import { checkPermission } from '@/utils'

export const tableOption = {
  search: checkPermission(['externaladmin:fedex:had:search']),
  searchLabelWidth: 88,
  dialogFormSpan: 24,
  formWidth: '500px',
  column: [
    {
      label: '原因类型',
      prop: 'refundType',
      search: true,
      type: 'select',
      dicType: 'refundReasonType',
      rules: [
        {
          required: true
        }
      ]
    },
    {
      label: '原因描述',
      prop: 'itemName',
      type: 'textarea',
      minRows: 4,
      rules: [
        {
          required: true
        }
      ]
    },
    {
      label: '添加时间',
      prop: 'createTime',
      addDisplay: false,
      editDisplay: false
    }
  ]
}
